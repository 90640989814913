import Cookies from 'universal-cookie'
import localConfig from '../../config/config'

import { combineReducers } from 'redux'
import {
    LOADING,
    SET_NOTIFICATION,
    ALTER_FIELDS,
    SHOW_QS,
    SET_TUTORIAL_ELEMENTS,
    SET_URL,
    SHOW_TERMS,
    SET_HELPER,
    SHOW_MI_HELPER,
    SET_SHOWING_LANGUAGE,
    SET_SHOWING_SOURCE,
    SET_PLATFORM_DISABLED,
    SET_QUERY_FORM_MODE
} from '../actions'

import {
    LOGGED_IN,
    LOGIN_FAILED,
    LOG_OUT,
    CHECK_LOGGED_IN,
    ASSERT_LOGGED_IN,
    REFRESH_TOKEN,
    SHOULD_REDIRECT_TO_LOGIN,
    ON_LOAD_PROFILE,
    SET_LOGIN_SCREEN_TYPE,
    SHOULD_REDIRECT_TO_PROJECTS
} from '../actionsAuth'

import {
    PROJECTS_LOADED,
    COMPANY_LOADED,
    SET_SHARING_PROJECT,
    SET_EDITING_PROJECT,
    SHOW_ONBOARDING,
    SHOULD_SHOW_MUST_INCLUDE_POPUP
} from '../actionsProjects'


import {
    MONITOR_PLATFORMS_LOADED,
    MONITOR_PLATFORM_DOCS_LOADED
} from '../actionsMonitor'

import {
    SET_QUERY,
    SET_QUERY_LANGUAGE,
    CHANGE_QUERY,
    SET_CHANGED_FILTERS,
    SET_DATES_FILTER,
    SET_CDATES_FILTER,
    SET_GROUP_VERSIONS,
    SET_GROUP_CASE,
    ON_SEARCH_RESULTS_LOADED,
    ON_QSSEARCH_RESULTS_LOADED,
    ON_QSSEARCH_QUERY_CHANGED,
    RESULTS_SHOULD_REDRAW,
    SHOW_QUERY_FORM,
    ON_PROJECT_PARAMS_LOADED,
    SHOW_SAVED_DOCS,
    SHOW_WATCHES,
    SET_BROWSING_DOC_ID,
    SHOW_RESULTS_MODE,
    DOC_LOADING,
    SET_LOADED_DOC,
    ADD_SELECTION,
    REMOVE_SELECTION,
    SET_TRANSLATION_STATE,
    SHOW_ACTIVITY,
    SET_MODIFIED_HIGHLIGHT_ID,
    SET_MODIFIED_COMMENT_ID,
    SHOULD_POST_SEARCH,
    ALTER_SAVED_DOC_ORDER_LOCALLY,
    SET_START_POSITION,
    SET_CURRENT_DOC_READ,
    ALTER_CURRENT_PROJECT_EDITOR,
    CLOSE_DOCUMENT,
    SET_USE_BY_DEFAULT
} from '../actionsProject'

import {
    PLATFORMS_LOADED,
    SUBSCRIBE,
    ALTER_PLATFORM_STATUS
} from '../actionsPlatforms'


var editDistance = require ('edit-distance')
var insertED, removeED, updateED;
insertED = removeED = function(node) { return 1; };
updateED = function(stringA, stringB) { return stringA !== stringB ? 1 : 0; };
 

function safeArray (candidate)
{
    if (candidate)
        return candidate
    else
        return []
}

function safeDict (candidate)
{
    if (candidate)
        return candidate
    else
        return {}
}


function query(
    state = {
        query: '',
        selectionMust: [],
        selectionMustPositions: [],
        selectionExclude: [],
        selectionExcludePositions: [],
        withTranslation: 0,
        changedFilters: {},
        unselectedPlatforms: {},
        groupVersions: false,
        minDate: null,
        maxDate: null,
        permitEmptyDate: true,
        minCDate: null,
        maxCDate: null,
        permitEmptyCDate: true,
        shouldPostSearch: false,
        treatmentLanguage: null,
        useByDefault: false,
        groupCase: false
    },
    action
) {
    var vstate
    switch (action.type) {
        case SET_PLATFORM_DISABLED:
            var unselected = Object.assign({}, state.unselectedPlatforms, {  })
            unselected[action.platformID] = !action.enabled
            return Object.assign({}, state, { unselectedPlatforms: unselected })
        case SHOULD_POST_SEARCH:
            return Object.assign({}, state, { shouldPostSearch: action.should })
        case SET_USE_BY_DEFAULT:
            return Object.assign({}, state, { useByDefault: action.value })
        case SHOULD_POST_SEARCH:
            return Object.assign({}, state, { shouldPostSearch: action.should })
        case SET_QUERY_LANGUAGE:
            return Object.assign({}, state, {treatmentLanguage:action.queryLanguage})
        case SET_QUERY:
            return Object.assign({}, state, {
                query: action.query.query,
                selectionMust: safeArray (action.query.selectionMust),
                selectionMustPositions: safeArray (action.query.selectionMustPositions),
                selectionExclude: safeArray (action.query.selectionExclude),
                selectionExcludePositions: safeArray (action.query.selectionExcludePositions),
                changedFilters: safeDict (action.query.changedFilters),
                unselectedPlatforms: safeDict (action.query.unselectedPlatforms),
                //changedFilters: {},
                minDate: action.query.minDate,
                maxDate: action.query.maxDate,
                permitEmptyDate: action.query.permitEmptyDate,
                minCDate: action.query.minCDate,
                maxCDate: action.query.maxCDate,
                permitEmptyCDate: action.query.permitEmptyCDate,
                shouldPostSearch: false,
                treatmentLanguage: action.query.treatmentLanguage,
                useByDefault: (action.query.useByDefault !== null) && action.query.useByDefault
            })
        case CHANGE_QUERY:
            vstate = Object.assign({}, state, {})
            
            var lev = editDistance.levenshtein(state.query, action.query, insertED, removeED, updateED);

            if (lev.distance > 0.9 * state.query.length)
                return Object.assign({}, state, {
                    query: action.query,
                    selectionMust: [],
                    selectionMustPositions: [],
                    selectionExclude: [],
                    selectionExcludePositions: [],     
                    shouldPostSearch: false       
                })


            var align = lev.alignment()

            
            align = [align.alignmentA, align.alignmentB]
            for (var i = 0; i < align[0].length; i += 1)
            {
                if (align[0][i] === align[1][i])
                    continue
                if (align[0][i] === null)
                {
                    for (const selType of ['selectionMust', 'selectionExclude'])
                        for (var si = 0; si < vstate[selType].length; si += 1)
                        {
                            if (vstate[selType + 'Positions'][si] >= i)
                                vstate[selType + 'Positions'][si] += 1
                            else if (vstate[selType + 'Positions'][si] + vstate[selType][si].length > i)
                                vstate[selType][si] = vstate[selType][si].slice(0, i - vstate[selType + 'Positions'][si]) + align[1][i] + vstate[selType][si].slice(i - vstate[selType + 'Positions'][si])
                        } 
                }
                else if (align[1][i] !== null) {
                    for (const selType of ['selectionMust', 'selectionExclude'])
                        for (si = 0; si < vstate[selType].length; si += 1)
                        {
                            if ((vstate[selType + 'Positions'][si] <= i) && (vstate[selType + 'Positions'][si] + vstate[selType][si].length > i))
                                vstate[selType][si] = vstate[selType][si].slice(0, i - vstate[selType + 'Positions'][si]) + align[1][i] + vstate[selType][si].slice(1 + i - vstate[selType + 'Positions'][si])
                        } 
                }
            }
            for (i = align[0].length - 1; i >= 0; i -= 1)
            {
                if (align[0][i] === align[1][i])
                    continue
                if (align[1][i] === null)
                {
                    for (const selType of ['selectionMust', 'selectionExclude'])
                        for (si = 0; si < vstate[selType].length; si += 1)
                        {
                            if (vstate[selType + 'Positions'][si] > i)
                                vstate[selType + 'Positions'][si] -= 1
                            else if (vstate[selType + 'Positions'][si] + vstate[selType][si].length >= i)
                                vstate[selType][si] = vstate[selType][si].slice(0, i - vstate[selType + 'Positions'][si]) + vstate[selType][si].slice(1 + i - vstate[selType + 'Positions'][si])
                        } 
                }
            }

            for (const selType of ['selectionMust', 'selectionExclude'])
                for (si = vstate[selType].length - 1; si >= 0 ; si -= 1)
                    if (vstate[selType][si].trim().length === 0)
                    {
                        vstate[selType].splice (si, 1)
                        vstate[selType + 'Positions'].splice (si, 1)
                    }
                
            vstate['query'] = action.query
            return vstate
        case SET_CHANGED_FILTERS:
            return Object.assign({}, state, {
                changedFilters: safeDict (action.changedFilters),
                shouldPostSearch: action.shouldPostSearch
            })
        case SET_DATES_FILTER:
            return Object.assign({}, state, {
                minDate: action.datesParams.minDate,
                maxDate: action.datesParams.maxDate,
                permitEmptyDate: action.datesParams.permitEmptyDate,
                shouldPostSearch: action.shouldPostSearch
            })
        case SET_CDATES_FILTER:
            return Object.assign({}, state, {
                minCDate: action.datesParams.minCDate,
                maxCDate: action.datesParams.maxCDate,
                permitEmptyCDate: action.datesParams.permitEmptyCDate,
                shouldPostSearch: action.shouldPostSearch
            })
        case SET_GROUP_VERSIONS:
            return Object.assign({}, state, {
                groupVersions: action.groupVersions
            })
        case SET_GROUP_CASE:
            return Object.assign({}, state, {
                groupCase: action.groupCase,
                shouldPostSearch: true
            })
        case ADD_SELECTION:
            vstate = Object.assign({}, state, {})
            vstate[action.selectionType].push (action.value)
            vstate[action.selectionType + 'Positions'].push (action.position)
            return vstate 
        case REMOVE_SELECTION:
            vstate = Object.assign({}, state, {})
            vstate[action.selectionType].splice (action.index, 1)
            vstate[action.selectionType + 'Positions'].splice (action.index, 1)
            return vstate 
        case SET_TRANSLATION_STATE:
            return Object.assign({}, state, {
                withTranslation: action.value
            })
        default:
            return state
    }
}

function projects(
    state = {
        company: { company:null, shouldShowMustIncludePopup:0 },
        list: [],
        docs: {},
        qsdocs: [],
        detectedLang: null,
        net: [],
        params: null,
        savedDocs: [],
        browsingDocId: null,
        loadedDoc: null,
        sharingProject: null,
        editingProject: null,
        browsingDocLang: null,
        refsCollection: [],
        qsquery: "",
        modifiedHighlightId: null,
        sessionSeenDocs: [],
        startPosition: 0,
        showingLanguage: null,
        showingSource: "Merged"
    },
    action
) {
    var vstate;
    switch (action.type) {
        case SET_CURRENT_DOC_READ:
            vstate = Object.assign({}, state, {})
            if (vstate.loadedDoc)
                vstate.loadedDoc.read = action.read
            return vstate
        case ALTER_SAVED_DOC_ORDER_LOCALLY:
            vstate = Object.assign({}, state, {})
            vstate.savedDocs[action.savedDocIndex].order = action.newOrder
            return vstate
        case SHOULD_SHOW_MUST_INCLUDE_POPUP:
            return Object.assign({}, state, {
                company: Object.assign({}, state.company, {
                        shouldShowMustIncludePopup: action.val
                    })})
        case SET_START_POSITION:
            return Object.assign({}, state, {
                startPosition: action.startPosition
            })
        case SET_SHARING_PROJECT:
            return Object.assign({}, state, {
                sharingProject: action.sharingProject
            })
        case SET_EDITING_PROJECT:
            return Object.assign({}, state, {
                editingProject: action.project
            })
        case SET_LOADED_DOC:
            vstate = Object.assign({}, state, {})
            vstate.loadedDoc = action.doc
            if (action.doc)
            {
                if (vstate.docs)
                    for (var lang of Object.keys(vstate.docs))
                        if (vstate.docs[lang])
                            for (var src of Object.keys(vstate.docs[lang]))
                                for (var i = 0; i < vstate.docs[lang][src].docs.length; i++)
                                    if (vstate.docs[lang][src].docs[i].ref === action.doc.ref)
                                        vstate.docs[lang][src].docs[i] = Object.assign({}, vstate.docs[lang][src].docs[i], action.doc)
                vstate.sessionSeenDocs = [action.doc.ref].concat(vstate.sessionSeenDocs)
            }
            return vstate
        case COMPANY_LOADED:
            return Object.assign({}, state, {
                company: action.company
            })
        case PROJECTS_LOADED:
            var nSharingProject = null
            if ((state.sharingProject !== null) && ((state.sharingProject !== undefined)))
                nSharingProject = action.projects.find(x => x.id === state.sharingProject.id)
            
            return Object.assign({}, state, {
                list: action.projects,
                sharingProject: nSharingProject
            })
        case SET_BROWSING_DOC_ID:
            return Object.assign({}, state, {
                browsingDocId: action.docId,
                browsingDocLang: action.lang,
                refsCollection: action.allRefs,
                loadedDoc: action.docId ? state.loadedDoc : null
            })
        case SHOW_SAVED_DOCS:
            return Object.assign({}, state, {
                browsingDocId: null,
                browsingDocLang: null,
                refsCollection: [],
                loadedDoc: null
            })
        case CLOSE_DOCUMENT:
            return Object.assign({}, state, {
                browsingDocId: null,
                browsingDocLang: null,
                refsCollection: [],
                loadedDoc: null
            })
        case SET_MODIFIED_HIGHLIGHT_ID:
            return Object.assign({}, state, {
                modifiedHighlightId: action.modifiedHighlightId
            })
        case SET_MODIFIED_COMMENT_ID:
            return Object.assign({}, state, {
                modifiedCommentId: action.modifiedCommentId
            })
        case SET_SHOWING_SOURCE:
            return Object.assign({}, state, {
                showingSource: action.src
            })
        case SET_SHOWING_LANGUAGE:
            return Object.assign({}, state, {
                showingLanguage: action.lang
            })
        case ON_SEARCH_RESULTS_LOADED:
            var res =  Object.assign({}, state, {
                docs: action.data.docs,
                net: action.data.net,
                params: action.data.params,
            })

            if (Object.keys(action.data.docs).length > 0)
            {
                if ((!res.showingLanguage) || !(res.showingLanguage in action.data.docs))
                    res.showingLanguage = Object.keys(action.data.docs)[0]
                if ((res.showingLanguage in action.data.docs) && ((!res.showingSource) || !(res.showingSource in action.data.docs[res.showingLanguage])) && (Object.keys(action.data.docs[res.showingLanguage]).length > 0))
                {
                    var cs = 0
                    var sources = Object.keys(action.data.docs[res.showingLanguage])
                    res.showingSource = sources[cs]
                    cs = 1
                    while ((cs < sources.length) && (action.data.docs[res.showingLanguage][res.showingSource].docs.length === 0))
                    {
                        res.showingSource = sources[cs]
                        cs += 1
                    }
                }

                var collection = [... action.data.docs[res.showingLanguage][res.showingSource].docs]
                if (collection.length > 0)
                {
                    collection.sort((a,b) => (a.score > b.score) ? -1 : 1);
                    //res.loadedDoc = collection[0].ref
                    res.browsingDocId = collection[0].ref
                    res.browsingDocLang = res.showingLanguage
                    res.refsCollection = collection.map ((d) => {return [d.ref]})
                }
                else
                {
                    //res.loadedDoc = null
                    res.browsingDocId = null
                    res.browsingDocLang = res.showingLanguage
                    res.refsCollection = []
                }
            }
            return res
        case ON_QSSEARCH_RESULTS_LOADED:
            return Object.assign({}, state, {
                qsdocs: action.data.qsdocs,
                detectedLang: action.data.lang,
                
            })
        case ON_QSSEARCH_QUERY_CHANGED:
            return Object.assign({}, state, {
                qsquery: action.query,
                
            })
        case ON_PROJECT_PARAMS_LOADED:
            var nstate = Object.assign({}, state, {
                currentProject: action.result.project
                
            })
            if (action.result.savedDocs)
                nstate.savedDocs = action.result.savedDocs
            return nstate
        case ALTER_CURRENT_PROJECT_EDITOR:
            var nstate = Object.assign({}, state, { })
            if (action.isEditor)
                nstate.currentProject.editors.push ({login: action.login, upid: action.upid})
            else
                nstate.currentProject.editors = nstate.currentProject.editors.filter (function(editor) { return editor.upid !== action.upid})
            return nstate
                    
        default:
            return state
    }
}




function platforms (
    state = {
        platforms: {'active':[], 'upcoming':[], 'proposal':[], 'contactUs':[]},
        subscribingPlatform: null
    }, action
) {
    switch (action.type) {
        case PLATFORMS_LOADED:
            return Object.assign({}, state, {
                platforms: action.platforms
            })
        case SUBSCRIBE:
            return Object.assign({}, state, {
                subscribingPlatform: action.platform
            })
        case ALTER_PLATFORM_STATUS:
            var vstate = Object.assign({}, state, {})
            for (var i = 0; i < vstate.platforms.active.length; i++)
            {
                if (vstate.platforms.active[i].id === action.platform.id)
                    vstate.platforms.active[i].hasAccess = action.status
            }
            return vstate
        default:
            return state
    }
} 


function monitor (
    state = {
        platforms: {'Europe':[], 'Nationals':[]},
        browsingPlatform: null,
        browsingPeriod: '2D',
        browsingDocs: null,
        browsingDecisions: true
    }, action
) {
    switch (action.type) {
        case MONITOR_PLATFORMS_LOADED:
            return Object.assign({}, state, {
                platforms: action.platforms
            })
        case MONITOR_PLATFORM_DOCS_LOADED:
            return Object.assign({}, state, {
                browsingDocs: action.docs,
                browsingPeriod: action.delay,
                browsingPlatform: action.platform,
                browsingDecisions: action.browsingDecisions
            })
        default:
            return state
    }
} 


function authParams(
  state = {
    auth: null,
    username: "",
    loggedIn: false,
    shouldRedirectToLogin: false,
    profile: {}
  },
  action
) {
    const cookies = new Cookies();
    switch (action.type) {
        case SHOULD_REDIRECT_TO_LOGIN:
            return Object.assign({}, state, {
                shouldRedirectToLogin: true
            })    
        case CHECK_LOGGED_IN:
            var loggedIn = false
            var vauth = cookies.get("sysauth")
            if ((vauth !== undefined) && (vauth !== null))
            {
                var at = vauth.access_token
                if ((at) && (at.length > 5) && (at !== undefined) && (at !== null))
                  loggedIn = true
            }
                
            return Object.assign({}, state, {
                loggedIn: loggedIn,
                username: cookies.get("sysusername"),
                //auth: vauth,
            })

        case ASSERT_LOGGED_IN:
            var loggedIn = false
            var vauth = cookies.get("sysauth")
            if ((vauth !== undefined) && (vauth !== null))
            {
                var at = vauth.access_token
                if ((at) && (at.length > 5) && (at !== undefined) && (at !== null))
                    loggedIn = true
            }
                
            return Object.assign({}, state, {
                loggedIn: loggedIn,
                username: cookies.get("sysusername"),
                shouldRedirectToLogin: !loggedIn
                //auth: vauth,
            })

        case LOGGED_IN:
            cookies.set('sysauth', action.auth, { path: '/' })
            cookies.set('sysusername', action.username, { path: '/' })
            
            return Object.assign({}, state, {
                loggedIn: true,
                username: action.username,
                auth: action.auth,
                shouldRedirectToLogin: false
            })
        case REFRESH_TOKEN:
            cookies.set('sysauth', action.auth, { path: '/' })
            
            return Object.assign({}, state, {
                auth: action.auth,
                shouldRedirectToLogin: false
            })
        
        case LOGIN_FAILED:
            cookies.set('sysauth', null, { path: '/' })
            cookies.set('sysusername', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                shouldRedirectToLogin: true
            })
        case LOG_OUT:
            cookies.set('sysauth', null, { path: '/' })
            cookies.set('sysusername', "", { path: '/' })
            return Object.assign({}, state, {
                loggedIn: false,
                username: "",
                auth: null,
                profile: {}
            })
        case ON_LOAD_PROFILE:
            return Object.assign({}, state, {
                profile: action.profile,
            })
        
        default:
            return state
    }
}

function globalInterface(
    state = {
        helper: null,
        loading: false,
        queryFormVisible: true,
        queryFormMode: 'full',
        watchesVisible: false,
        savedDocsVisible: false,
        activityVisible: false,
        activityTab: 'All',
        
        resultsShouldRedraw: false,
        showResultsMode: localConfig.defaultResultsMode ? localConfig.defaultResultsMode : 'list',
        docIsLoading: false,
        showQS: false,
        onboardingDismissed: true,
        tutorialActiveElements: {},
        toSetURL: null,
        shouldShowTerms: false,
        MIHelperVisible: false,
        
        loadingProgress: null,
        notificationHeader: null,
        notificationContent: null,
        notificationType: null,
        notificationClass: null,
        loginScreenType: 'login',
        alteredFields: {}
    },
    action
) {
    switch (action.type) {
        case SET_QUERY_FORM_MODE:
            return Object.assign({}, state, {
                queryFormMode: action.queryFormMode
            })
        case SHOW_MI_HELPER:
            return Object.assign({}, state, {
                MIHelperVisible: action.visible
            })
        case SET_HELPER:
            return Object.assign({}, state, {
                helper: action.helper
            })
        case SET_URL:
            return Object.assign({}, state, {
                toSetURL: action.url
            })
        case SET_TUTORIAL_ELEMENTS:
            if (!action.elements)
                return Object.assign({}, state, {
                    tutorialActiveElements: {}
                })
            var cte = Object.assign({}, state.tutorialActiveElements,action.elements)
            if (action.update)
            {
                cte = Object.assign({}, state.tutorialActiveElements,{})
                Object.keys(action.elements).forEach(function(key) {
                    if (cte.hasOwnProperty(key))
                        cte[key] = action.elements[key];
                    
                });
            }
            
            return Object.assign({}, state, {
                tutorialActiveElements: cte
            })
        case SHOW_ONBOARDING:
            return Object.assign({}, state, {
                onboardingDismissed: !action.visible
            })
        case DOC_LOADING:
            return Object.assign({}, state, {
                docIsLoading: action.isLoading
            })
        case ALTER_FIELDS:
            if (action.changes)
                return Object.assign({}, state, {
                    alteredFields: Object.assign({}, state.alteredFields, action.changes)
                })
            else
                return Object.assign({}, state, {
                    alteredFields: {}
                })
        case SET_LOGIN_SCREEN_TYPE:
            return Object.assign({}, state, {
                loginScreenType: action.screenType
            })
        case LOADING:
            if (action.isLoading)
                    return Object.assign({}, state, {
                        notificationHeader: null,
                        notificationContent: null,
                        notificationType: null,
                        notificationClass: null,
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
                else
                    return Object.assign({}, state, {
                        loading: action.isLoading,
                        loadingProgress: action.isLoading ? action.loadingProgress : null
                    })
        case SET_NOTIFICATION:
            return Object.assign({}, state, {
                notificationHeader: action.header,
                notificationContent: action.content,
                notificationType: action.ntype,
                notificationClass: action.nclass,
            })
        case ON_SEARCH_RESULTS_LOADED:
            return Object.assign({}, state, {
                queryFormVisible: false,
                resultsShouldRedraw: true
            })
        case RESULTS_SHOULD_REDRAW:
            return Object.assign({}, state, {
                //queryFormVisible: false,
                resultsShouldRedraw: action.shouldRedraw
            })
        case SHOW_QUERY_FORM:
            return Object.assign({}, state, {
                watchesVisible: false,
                savedDocsVisible: false,
                indexVisible: false,
                activityVisible: false,
                queryFormVisible: action.visible,
            })
        case SHOW_ACTIVITY:
            return Object.assign({}, state, {
                watchesVisible: false,
                savedDocsVisible: false,
                indexVisible: false,
                activityVisible: action.visible,
                activityTab: action.tab,
                queryFormVisible: false,
            })
        case SHOW_SAVED_DOCS:
            return Object.assign({}, state, {
                watchesVisible: false,
                savedDocsVisible: action.visible,
                indexVisible: false,
                activityVisible: false,
                queryFormVisible: false
            })
        case SHOW_WATCHES:
            return Object.assign({}, state, {
                watchesVisible: action.visible,
                savedDocsVisible: false,
                indexVisible: false,
                activityVisible: false,
                queryFormVisible: false
            })
        case SHOW_RESULTS_MODE:
            return Object.assign({}, state, {
                showResultsMode: action.mode,
            })
        case SHOW_QS:
            return Object.assign({}, state, {
                showQS: action.visible,
            })
        case SHOW_TERMS:
            return Object.assign({}, state, {
                shouldShowTerms: action.visible,
            })
        default:
        return state
  }

}

export default combineReducers({
    authParams,
    globalInterface,
    projects,
    query,
    platforms,
    monitor
  
})